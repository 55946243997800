.pre-auth-landing {
  height: 100%;
  display: flex;

  .container {
    flex: 1;
    position: relative;

    &.branding {
      flex: 1.2;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .background-tilted {
        position: absolute;
        background: $green-gradient;
        height: 150%;
        width: 150%;
        transform: rotate(5deg);
        right: 70px;
        top: -70px;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
      }

      .content {
        position: absolute;
        height: 40%;
        width: 60%;
        margin-left: -5%;

        .logo {
          width: 100%;
        }

      }

    }
  }
  .message-container {
    display: flex;
    width: 40%;
    align-self: center;
    .message {
      font-size: larger;

    }
  }
}
