.SelectInputDeviceModal,
.AttachUrlModal {
  padding: 40px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  .sub-title {
    margin: 20px 0px;
    text-align: center;
  }

  .inputs {
    .dropdown-action {
      .dropdown-content {
        top: 25px;
        bottom: unset;
      }

      .container {
        margin: 50px 0px;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          .selector {
            color: $teal;
          }

          svg {
            fill: $teal;
          }
        }

        .selector {
          color: darken($teal, 10%);
          max-width: 150px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        svg {
          height: 14px;
          fill: darken($teal, 10%);
          margin-left: 10px;
        }

        & + .container {
          margin-left: 30px;
        }

        &.recording {
          color: darken($teal, 40%);

          &:hover {
            .selector {
              color: darken($teal, 40%);
            }

            svg {
              fill: darken($teal, 40%);
            }
          }

          .selector {
            color: darken($teal, 40%);
          }

          svg {
            fill: darken($teal, 40%);
          }
        }
      }
    }
  }

  .save-devices {
    width: 60%;
    margin: 25px auto;
  }
}

.AttachUrlModal {
  padding: 20px 0px;

  .preview-url {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gray;
    border-radius: $border-radius-lg;
    height: 100%;
    position: relative;

    p {
      color: $white;
    }

    iframe {
      border-radius: $border-radius-lg;
      object-fit: cover;
      height: 400%;
      min-width: 400%;
      -moz-transform: scale(0.25, 0.25);
      -webkit-transform: scale(0.25, 0.25);
      -o-transform: scale(0.25, 0.25);
      -ms-transform: scale(0.25, 0.25);
      transform: scale(0.25, 0.25);
    }

    .iframe-cover {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .mini-text {
    font-size: $text-xs;
    margin-top: 2px;
    margin-left: 2px;
  }

  .actions {
    height: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .attach-url-input {
      width: 100%;
      margin: 30px 0px;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .Button {
        width: 46%;
      }
    }
  }
}

.Record {
  background: $black;
  height: 100%;
  padding: 0px 80px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  .navigation-container {
    height: 70px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $teal;

    .back-button {
      cursor: pointer;
      display: flex;
      font-size: $text-sm;

      svg {
        margin-right: 5px;
      }
    }

    .topic-info {
      color: $white;

      .preview {
        padding: 5px 10px;
        background: $darkgray;
        border-radius: 15px;
      }
    }

    .actions {
      position: relative;
      display: flex;

      .add-text {
        font-weight: bold;
        margin-right: 20px;
        margin-top: 5px;
        cursor: pointer;

        &.active {
          svg {
            text {
              fill: $matteteal;
            }
          }
        }

        svg {
          text {
            font-size: 22px;
          }
        }
      }

      .upload-file,
      .attach-url {
        min-height: 25px;
        min-width: 25px;
        display: flex;
        align-items: center;

        & + .upload-file,
        & + .attach-url {
          margin-left: 15px;
        }

        #upload-file-btn {
          display: none;
        }

        &.attach-url {
          svg {
            height: 20px;
            width: 20px;
          }
        }

        svg {
          cursor: pointer;
          height: 25px;
          max-width: 25px;
          fill: $white;
        }
      }

      .attach-url-btn {
        z-index: 100;
        position: absolute;
        top: 30px;
        right: 0px;
        color: $white;
        background: transparent;
        border: 1px solid $white;

        .attach-url-btn-icon {
          margin-right: 5px;
          border-radius: $border-radius-sm;
        }

        .attach-url-btn-close {
          padding: 5px;
          height: 10px;
          width: 10px;
          margin-left: 5px;

          svg,
          line {
            stroke: $white;
          }
        }
      }
    }
  }

  .video-player {
    .record-preview-container {
      border: 1px solid transparent; // fix sizing issues in relation to other elements on the page
      position: relative;
      height: 100%;
      max-height: calc(100vh - 140px); // the full screen height minus the top and bottom containers
      width: 100%;
      // transform: scaleX(-1); // this makes the live-preview video non-mirrored

      #audio-preview,
      #text-preview {
        border: 1px solid $mattegray;
        height: 80%;
        width: 100%;
        margin-top: 5%;

        &#audio-preview {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .user-image {
            height: 140px;
            width: 140px;
            border-radius: 100%;
            border: 1px solid $white;
          }

          .user-name {
            margin-top: 10px;
            color: $white;
          }
        }

        &#text-preview {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: text;

          @each $colorName, $colorValue in $text-colors {
            &.color-#{$colorName},
            &.color-#{$colorName} .upload-text,
            &.color-#{$colorName} .upload-text::placeholder {
              color: $colorValue;
            }
          }

          .upload-text {
            font-size: $text-md;
            width: 400px;
            padding-top: 20px;
            line-height: 1.3;
            background: unset;
            border: unset;
            outline: unset;
            resize: unset;
            text-align: center;
          }

          .upload-text-max {
            margin-top: -20px;
            font-size: $text-xs;
          }
        }
      }

      .add-text-overlay {
        position: absolute;
        width: 100%;
        top: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .input-text {
          background: transparent;
          border: 0px;
          text-align: center;
          color: $white;
          font-size: $text-md;
          height: $text-md;
          resize: unset;

          &::placeholder {
            color: $white;
          }

          &:focus {
            outline: unset;
          }
        }

        .max-chars {
          color: $white;
          margin-top: 5px;
          font-size: $text-xs;
        }
      }
    }
  }

  .controls {
    height: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .input-selectors {
      height: 100%;
      display: flex;
      align-items: center;

      .levels {
        height: 100%;
        width: 30px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .volume {
          height: 4px;
          width: 100%;
          background: $white;
          border-radius: $border-radius-lg;

          & + .volume {
            margin-top: 4px;
          }

          &.active {
            background: $teal;
          }
        }
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        cursor: pointer;

        .selector {
          color: $white;
          max-width: 150px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        svg {
          height: 14px;
          fill: $teal;
          margin-left: 10px;
        }

        & + .container {
          margin-left: 30px;
        }

        // currently recording, so make disabled
        &.recording {
          color: darken($white, 40%);

          .selector {
            color: darken($white, 40%);
          }

          svg {
            fill: darken($white, 40%);
          }
        }
      }

      .audio,
      .video {
        color: $white;
        display: block;
      }

      .text {
        cursor: unset;
        height: 80%;

        .color-selector {
          height: 25px;
          width: 25px;
          border-radius: 100%;
          padding: 2px;

          .icon {
            height: 100%;
            width: 100%;
            border-radius: 100%;
            cursor: pointer;
          }

          & + .color-selector {
            margin-left: 10px;
          }

          @each $colorName, $colorValue in $text-colors {
            &.#{$colorName} {
              .icon {
                background: $colorValue;
              }

              &.active {
                border: 1px solid $colorValue;
              }
            }
          }
        }
      }
    }

    .meta {
      height: 100%;
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      .RecordingTimeCounter {
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
          display: block;
          height: 5px;
          width: 5px;
          border-radius: 100%;
          background: $lightgreen;
          margin-right: 5px;
        }

        p {
          color: $white;
        }
      }
    }

    .record {
      display: flex;
      align-items: center;

      .media-types-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .media-type {
          width: 30px;
          margin-right: 5px;
          cursor: pointer;

          rect,
          path,
          text {
            fill: $white;
          }

          &.active {
            rect,
            path,
            text {
              fill: $teal;
            }
          }
        }
      }

      .discard-button,
      .pause-button {
        margin-right: 20px;
        padding: 0px 15px;
        background: transparent;
        color: $white;

        &:hover {
          color: $teal;
        }
      }

      .record-button {
        height: 60px;
        width: 60px;
        border: 3px solid $white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .inner-shape {
          height: 80%;
          width: 80%;
          background: $pink;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: border-radius 0.5s ease-in-out, height 0.3s ease-in-out,
            width 0.3s ease-in-out, background-color 0.2s ease-in-out;

          svg {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            fill: $white;
            height: 25px;
            width: 25px;
          }

          &:hover {
            background: darken($pink, 30%);
          }
        }

        &.stop {
          .inner-shape {
            height: 60%;
            width: 60%;
            border-radius: 15%;
          }
        }

        &.upload {
          border: unset;

          .inner-shape {
            background: $green;
            height: 100%;
            width: 100%;
            border-radius: 100%;

            svg {
              opacity: 1;
            }

            &:hover {
              background: darken($green, 30%);
            }
          }
        }

        &.loading {
          .inner-shape {
            .loader {
              animation: spin 1s linear infinite;
              height: 50%;
              width: 50%;
              border-radius: 100%;
              border-top: 3px solid $white;
              border-right: 3px solid transparent;
              border-left: 3px solid $white;
              border-bottom: 3px solid transparent;
            }
          }
        }
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .Record {
    padding: 0px 20px;

    .controls {
      flex-direction: row-reverse;
      justify-content: center;
      flex-wrap: wrap;
      height: auto;

      .input-selectors {
        height: unset;
        width: 100%;

        .text {
          width: 100%;
        }

        .levels,
        .audio,
        .video {
          display: none;
        }
      }

      .record,
      .meta {
        height: 100%;
        padding-bottom: 20px;

        .record-button.loading {
          padding-top: unset;
        }
      }
    }
  }
}
