.Modal {
  margin-bottom: 20px;
  outline: none;
  min-height: 80%;
  min-width: 500px;
  width: 60%;
  border-radius: $border-radius-lg;
  padding: 40px;
  background: $white;
  position: absolute;
  top: 10%;
  left: 20%;
  box-shadow: 6px 6px 20px lighten($darkgray, 15);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .modal-title {
    margin-bottom: 15px;
    font-size: 18px;
    color: $lightblue;
  }

  .modal-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .modal-content {
    min-width: 400px;
    width: 80%;
  }

}

.Overlay {
  overflow: scroll;
  z-index: 1000000;
  height: 100vh;
  width: 100vw;
  background: transparentize($darkgray, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
}
