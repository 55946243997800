.UserSettingsModal {
  .logout {
    background: unset;
    min-height: unset;
    padding: 5px;
    color: $matteteal;
    position: absolute;
    right: 14px;
    top: 8px;
    cursor: pointer;

    &:hover {
      background: unset;
      color: $darkteal;
    }
  }

  .copy {
    margin: 10px;
    text-align: center;
    line-height: 130%;
  }

  .profile-picture {
    margin-top: 10px;
  }

  .profile-toggle-settings {
    padding: 5px 0px 20px 0px;
    border-bottom: 1px solid $lightmattegray;
  }

  .InputField {
    margin: 12px auto;
  }

  .button-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.row {
      flex-direction: row;
    }

    .user-settings-button {
      width: 50%;
    }

    .cancel {
      display: flex;
      margin-right: 20px;
      color: $red;
      cursor: pointer;
      
      p + p {
        margin-left: 5px;
      }
    }

    .report-problem {
      margin-top: 15px;
      color: $matteteal;

      &:hover {
        color: $darkteal;
      }
    }
  }
}
