.FileUpload {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  // this is the actual <input /> element, but we want to hide it because it's hard to style
  .file-actual {
    display: none;
  }

  // instead we're going to style the <label /> for the input field above. this is much easier to style and still works just fine
  .file-pretty,
  .file-preview {
    cursor: pointer;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: $border-radius-lg;
    color: $white;
    font-size: 18px;
    font-weight: 600;

    &.rounded {
      border-radius: 50%;
    }
  }

  .file-image {
    border-radius: $border-radius-lg;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: unset;
  }

  .file-pretty {
    background: $green-gradient;
    
    &.full {
      background: transparent;
    }
    
    .no-file-preview {
      border-radius: $border-radius-lg;
      background: $green-gradient;
      position: relative;
      height: 100%;
      width: 100%;

      .file-pretty-img {
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 13px);
        max-height: 26px;
        max-width: 26px;
      }

      .file-pretty-plus {
        position: absolute;
        top: 50%;
        left: calc(50% + 15px);
      }
    }
  }

  .file-preview {
    overflow: hidden;
    position: absolute;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .file-preview-change-img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
    }
  }
}
