@mixin default-button {
  height: 35px;
  width: 60%;
  border: none;
  border-radius: $border-radius-sm;
  background: $lightblue;
  color: $white;
}

.invalid-input {
  border: 1px solid red !important;
}

.disabled-btn {
  background-color: gray !important;
  opacity: .5 !important;
  cursor: default !important;
}
