
// Custom styles for the PhoneInput component from the react-phone-number-input package
.auth-input-parent {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: left;

  input {
    border-bottom: 1px solid gray;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}
