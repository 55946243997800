.SmallModal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba($black, 0.5);
    backdrop-filter: blur(30px);
  }

  .close-button {
    padding: 5px;
    height: 25;
    width: 25;
    border-radius: $border-radius-sm;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg,
    path {
      fill: $teal;
      height: 12px;
      width: 12px;
    }

    p {
      margin-left: 5px;
      color: $teal;
      font-weight: 600;
    }

    &:hover {
      svg,
      path {
        fill: darken($teal, 10);
      }

      p {
        color: darken($teal, 10);
      }
    }
  }

  .content {
    height: 80%;
    max-height: 500px;
    min-height: 400px;
    width: 400px;
    max-width: 90%;
    background: $white;
    border-radius: $border-radius-xl;
    position: relative;
    padding: 20px;

    &.tall {
      max-height: 650px;
      min-height: 600px;
    }

    .children {
      height: 100%;
    }
  }

  &.no-width {
    .content {
      width: unset;
      min-width: 400px;
    }
  }
}

// this media query changes the way the modal content is rendered on mobile, allowing it to work specifically on landscape mode.
@media (orientation: landscape) and (max-height: 768px) {
  .SmallModal {
    .content {
      min-height: unset;
      max-width: 90%;
      overflow: scroll;
      position: relative;

      &.tall {
        min-height: 80vh;
        max-height: 85vh;
      }

      .close-button {
        position: fixed;
        top: unset;
        right: unset;
        margin-top: -30px;
        margin-left: 370px;
      }
    }

    &.no-width {
      .content {
        .close-button {
          margin-left: 510px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .SmallModal {
    &.no-width {
      .content {
        width: unset;
        min-width: unset;
      }
    }
  }
}
