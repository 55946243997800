.message-modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(50, 50, 50, 0.5);
  z-index: 9999;

  .message-modal {
    width: 300px;
    height: 300px;
    position: fixed;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
    background: white;
    padding: 20px;
    font-size: 18px;
    border-radius: 5px;

    .message-modal-text {
      width: 260px;
      height: 210px;
      overflow: scroll;
    }

    .close-button {
      width: 260px;
      height: 30px;
      position: absolute;
      bottom: 20px;
      background: #009dca;
      color: white;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}
