.Button {
  min-height: 40px;
  border-radius: $border-radius-sm;
  border: none;
  color: $white;
  font-size: 16px;

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.primary {
    background: $matteteal;
    color: $white;

    &:hover {
      background: darken($matteteal, 5);
    }
  }

  &.secondary {
    background: $lightblue;
  }

  &.critical {
    background: rgba($red, 0.3);
    color: $red;

    &:hover {
      background: rgba($red, 0.4);
    }
  }

  &.disabled {
    background: $gray;
    color: $white;
    cursor: not-allowed;

    &:hover {
      background: $gray;
    }
  }

  .icon {
    height: 12px;
    margin-right: 10px;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
}

.Loading {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
