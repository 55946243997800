.topic-creator {
  display: flex;
  justify-content: flex-start;
  padding: 4px;
  border: solid 1px #a5a5a5;
  border-radius: 4px;
}
.topic-creator-image {
  height: 40px;
  width: 40px;
}

.topic-input-container {
  display: flex;
  align-items: center;
}

.topic-button {
  width: 80px;
}

.topic-creator-sub-container {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;

  padding-left: 10px;
}

.topic-creator-name {
  font-size: large;
}

.topic-creator-title {
  font-size: small;
}
