.CreateTopicModal,
.CreateDMModal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .modal-title {
    text-align: center;
    font-weight: 600;
  }

  .modal-button {
    width: 100%;
  }

  .FileUpload {
    margin-top: 30px;
  }

  .list {
    margin: 30px 0px;
    flex: 1;
    overflow: scroll;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;

      .meta {
        display: flex;
        align-items: center;
        cursor: pointer;

        .img {
          height: 25px;
          width: 25px;
          margin-right: 10px;
          border-radius: 50%;

          &.empty {
            background: $green-gradient;
          }
        }
      }

      .checkbox {
        height: 20px;
        width: 20px;
        justify-self: flex-end;
        cursor: pointer;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    .modal-button {
      width: 48%;
    }
  }
}

.Group {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.loading {
    align-items: center;
    justify-content: center;

    img {
      height: 50px;
    }
  }

  .create-new-topic-container {
    padding: 20px 20px 0px;

    .add-new-btn {
      width: fit-content;
      padding: 3px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      font-size: $text-sm;
      color: $darkblue;

      svg {
        height: 35px;
        width: 35px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .topics-container {
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: scroll;

    .sub-group-container {
      margin-bottom: 40px;
      min-height: 230px;
      text-align: center;
      display: flex;
      flex-direction: column;
      background-color: $white;
      height: auto;
      width: 100%;
      border-radius: 6px;

      .preview-label-container {
        flex: 1;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .preview-image {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .topic-img {
            border-radius: $border-radius-lg;
            height: 50px;
            width: 50px;
          }

          .preview-label {
            margin-left: 10px;
            font-size: $text-md;
            color: $darkblue;
            cursor: pointer;

            .name-display {
              text-align: left;
            }

            .time-display {
              font-size: $text-xs;
              margin-top: 3px;
              text-align: left;
            }
          }
        }

        .group-create-topic-menu-btn {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            flex: 1;
            cursor: pointer;
          }
        }
      }

      .preview-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;

        .group-preview {
          position: relative;

          .preview-giphy {
            height: 300px;
            max-height: 135px;
            width: 200px;
            max-width: 90px;
            border-radius: $border-radius-xl;
            cursor: pointer;

            &.empty {
              display: flex;
              justify-content: center;
              align-items: center;
              background: rgba($black, 0.8);
              color: $white;
            }

            &.blue-border {
              border: 3px solid $blueborder;
            }
          }

          .preview-overlay {
            border-radius: $border-radius-xl;
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 30px;
            bottom: 0px;
            left: 0px;
            background: $black-gradient;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            &.fullsize {
              height: 100%;
              bottom: 0px;
              align-items: center;
              background: rgba($black, 0.7);
            }

            .timestamp {
              margin-bottom: 5px;
              font-size: $text-xs;
              cursor: pointer;
              color: $white;
            }

            .label {
              font-size: $text-md;
              color: $white;
            }
          }
        }

        .group-preview + .group-preview {
          display: flex;
          margin-left: 24px;
        }
      }

      &:nth-of-type(5n + 1) {
        .topic-img {
          background: $green-gradient;
        }
      }

      &:nth-of-type(5n + 2) {
        .topic-img {
          background: $blue-gradient;
        }
      }

      &:nth-of-type(5n + 3) {
        .topic-img {
          background: $lime-gradient;
        }
      }

      &:nth-of-type(5n + 4) {
        .topic-img {
          background: $orange-gradient;
        }
      }

      &:nth-of-type(5n + 5) {
        .topic-img {
          background: $purple-gradient;
        }
      }
    }
  }
}
