.new-group-modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .modal-title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: $text-md;
  }

  .modal-button {
    width: 150px;
    margin: auto;
  }
}

.group-invite-modal {
  height: 100%;
  position: relative;

  .modal-title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: $text-md;
  }

  .modal-subtitle {
    margin-bottom: 20px;
    text-align: center;
  }

  .group-invite-modal-button {
    width: fit-content;
    padding: 12px;
    background: $lightgreen;
    border-radius: 4px;
    cursor: pointer;
  }

  .bottom-nav-buttons {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 50px;

    .done-button {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
}

.Sidebar {
  width: $sidebar-width;
  background: $darkblue;
  display: grid;
  grid-template-rows: $header-height 1fr 60px;
  transition: width 0.5s ease-in-out;

  .grid-item {
    width: $sidebar-width;
    transition: width 0.5s ease-in-out, padding 0.5s ease-in-out;
    border-bottom: 1px solid $mattedarkblue;
    padding-left: 30px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    &.overflow {
      overflow: initial;
    }

    &.centered {
      padding-left: 0px;
      justify-content: center;
    }
  }

  &.hide {
    width: 0px;

    .grid-item {
      width: 0px;
      padding: 0px;
    }
  }

  .user-profile {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .profile-img {
      border-radius: 50%;
      background: $green-gradient;
      height: 35px;
      width: 35px;
    }

    .user-name {
      color: $white;
      box-sizing: border-box;
    }

    &:hover {
      .user-name {
        text-decoration: underline $mattelightblue;
      }
    }
  }

  .notifications-dropdown {
    width: 80%;

    .dropdown-trigger > .notifications {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .dropdown-content {
      background: $white;
      height: 400px;
      width: 400px;
      max-width: 85vw;
      max-height: 80vh;
      border: 1px solid $mattegray;
      box-shadow: 3px 3px 10px 0px rgba($darkgray, 0.4);

      &::before {
        content: ' ';
        height: 0px;
        width: 0px;
        position: absolute;
        display: block;
        bottom: -10px;
        left: 25px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $white;
      }
    }

    .notifications-list {
      position: relative;
      max-height: 100%;
      overflow: scroll;
      padding-top: 20px;

      .no-notifications {
        text-align: center;
      }

      .settings-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        max-height: 15px;
        max-width: 15px;
        cursor: pointer;
        z-index: 1000;

        svg,
        path {
          fill: $darkgray;
        }
      }

      .notification-item {
        border-top: unset;
        max-height: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        img {
          height: 25px;
          width: 25px;
          min-height: 25px;
          min-width: 25px;
          margin-right: 10px;
          border-radius: 50%;

          margin-top: 2px;
        }

        .label {
          color: $black;
        }

        .timestamp {
          color: $gray;
          margin-top: 2px;
          font-size: $text-xs;
        }

        &:hover {
          background: $lightgray;
        }
      }
    }

    .notifications {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $white;

      .notifications-icon {
        max-height: 20px;
        max-width: 20px;
        margin-right: 10px;

        svg,
        path {
          fill: $white;
        }
      }

      .notification-label {
        align-self: center;
      }

      .notification-image-container {
        height: 35px;
        width: 35px;
        padding-right: 10px;
        align-items: center;
      }
    }
  }

  .group-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    h4 {
      padding: 15px 20px;
      color: $white;
      font-size: $text-lg;
      font-weight: bold;
      margin: 0px;
      width: 100%;
    }

    .group-list {
      padding-left: 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: scroll;

      .group-item {
        min-height: 70px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;
        position: relative;

        .unwatched-content-badge {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $white;
          position: absolute;
          left: -14px;
          top: 21x;
        }

        &#group-item:nth-of-type(5n + 1) {
          .group-img {
            background: $green-gradient;
          }
        }

        &#group-item:nth-of-type(5n + 2) {
          .group-img {
            background: $blue-gradient;
          }
        }

        &#group-item:nth-of-type(5n + 3) {
          .group-img {
            background: $lime-gradient;
          }
        }

        &#group-item:nth-of-type(5n + 4) {
          .group-img {
            background: $orange-gradient;
          }
        }

        &#group-item:nth-of-type(5n + 5) {
          .group-img {
            background: $purple-gradient;
          }
        }

        &:hover,
        &.selected {
          .group-name {
            color: $white;
            font-weight: 700;
            width: 100%;
            height: 50px;
            line-height: 50px;
          }
        }

        &.selected {
          border-right: 5px solid $lightgreen;
        }

        .group-img {
          border-radius: $border-radius-xl;
          height: 50px;
          min-height: 50px;
          width: 50px;
          min-width: 50px;
        }

        .new-group-button {
          border: 1px dashed $mattelightblue;
          border-radius: $border-radius-xl;
          height: 50px;
          width: 50px;
          min-width: 50px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .group-img {
            height: 20px;
            width: 20px;
            background: unset;
            min-width: unset;
            min-height: unset;
          }
        }
      }
    }
  }

  .group-name,
  .user-name {
    padding-left: 15px;
    color: $mattegray;
    font-family: 'Source Sans Pro', 'Lato';
    font-weight: 400;
    font-size: $text-sm;
  }
}