.TopicSettingsModal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  hr {
    width: 100%;
    border: 1px solid $lightmattegray;
  }

  .topic-creator {
    border: none;

    .topic-creator-image {
      height: 35px;
      width: 35px;
      border-radius: $border-radius-sm;
      background: $green-gradient;
    }

    .topic-creator-sub-container {
      .topic-creator-title {
        color: $mattegray;
      }
    }
  }

  .toggles {
    flex: 1;
    height: 100%;
  }

  .button-container {
    height: 40px;
    display: flex;
    position: relative;
    user-select: none;

    .action-confirmation {
      width: 100%;
      height: fit-content;
      background: white;
      position: absolute;
      left: 0px;
      top: 0px;
      text-align: center;

      .button-wrapper {
        width: 100px;
        margin: 0px auto;
        display: flex;
        justify-content: space-around;
        padding-top: 2px;
      }
    }

    .btn {
      flex: 1;

      &.delete-topic {
        color: $red;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          cursor: pointer;
          margin: 3px;
        }
      }
    }
  }
}
