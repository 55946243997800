.RequestPermissionsModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    text-align: center;
    font-size: $text-lg;
    font-weight: bold;
    color: $darkblue;
  }

  .empty-video {
    height: 200px;
    width: 100%;
    background: $gray;
    border-radius: $border-radius-sm;
    position: relative;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    svg,
    path {
      max-height: 50px;
      max-width: 50px;
      fill: $white;
    }

    .not-active {
      width: 100%;
      background: $mattegray;
      padding: 10px;
      border-radius: $border-radius-sm;

      .not-active-text {
        color: $white;
        margin: auto;
        text-align: center;
      }
    }
  }
  .copy-title {
    text-align: center;
    font-size: $text-md;
  }

  .copy-body {
    text-align: center;

    strong {
      font-weight: bold;
    }
  }
}
