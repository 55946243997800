.DirectMessages {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .create-new-dm-container {
    padding: 20px 40px;

    .button {
      width: fit-content;
      padding: 3px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      color: $lightblue;

      .icon-container {
        height: 32px;
        width: 32px;
        margin-right: 2px;
        background: $white;
        border-radius: $border-radius-md;
        display: flex;
        border-color: $darkteal;
        border-width: thin;
        border-style: dashed;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 0px;
          height: 60%;
          color: $darkteal;
        }
      }

      .icon {
        display: flex;
        height: 18px;
        margin-right: 2px;
        color: $darkteal;
      }

      .label {
        padding-left: 10px;
        color: $darkteal;
      }
    }
  }

  .topics-container {
    overflow: scroll;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 40px;

    .sub-group-container {
      .topic-img {
        border-radius: $border-radius-lg;
      }

      &:nth-of-type(5n + 1) {
        .topic-img {
          background: $green-gradient;
        }
      }

      &:nth-of-type(5n + 2) {
        .topic-img {
          background: $blue-gradient;
        }
      }

      &:nth-of-type(5n + 3) {
        .topic-img {
          background: $lime-gradient;
        }
      }

      &:nth-of-type(5n + 4) {
        .topic-img {
          background: $orange-gradient;
        }
      }

      &:nth-of-type(5n + 5) {
        .topic-img {
          background: $purple-gradient;
        }
      }

      .preview-label-container {
        justify-content: space-between;

        .preview-label {
          margin-left: 0px;
        }

        .dm-menu-button {
          position: relative;
          user-select: none;

          .delete-dm-confirmation {
            width: 220px;
            padding: 10px;
            background: white;
            text-align: center;
            border: 1px solid black;
            border-radius: 5px;
            position: absolute;
            right: 0px;
            top: 0px;
      
            .button-wrapper {
              width: 100px;
              margin: 0px auto;
              display: flex;
              justify-content: space-around;
              padding-top: 6px;
            }
          }
        }

        .dm-hide-img{
          cursor: pointer;
        }
      }

      .name-display {
        text-align: left;
      }

      .time-display {
        font-size: 13px;
        margin-top: 3px;
        text-align: left;
      }

      .preview-container {
        .group-preview {
          .preview-giphy {
            max-height: 135px;
            max-width: 90px;
            border-radius: $border-radius-xl;
          }
          .blue-border {
            border: 3px solid $blueborder;
          }
        }
      }
    }
  }
}
