.InputField {
  position: relative;
  min-height: 40px;
  width: 100%;
  margin: 15px 0px;
  display: flex;
  flex-direction: column;

  &.TextArea {
    height: 100%;
  }

  .input-field-label {
    font-size: 10px;
    color: $darkgray;
    padding-left: 1px;
    margin-bottom: 5px;

    .required {
      color: $red;
      font-weight: bold;
    }
  }

  .input-error {
    display: inline;
    color: $red;
    font-size: 10px;
    margin-left: 10px;
  }

  .input-field,
  .text-area,
  .PhoneInputInput {
    height: 100%;
    width: 100%;
    min-height: 40px;
    border-radius: $border-radius-sm;
    border: unset;
    background: $lightgray;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
    color: $input-value;

    &.text-area {
      min-height: 120px;
    }

    &.disabled {
      background: $gray;
      cursor: not-allowed;

      &::placeholder {
        color: $input-disabled;
      }
    }

    &::placeholder {
      color: $input-placeholder;
    }

    &:focus {
      outline: none;
      background: $lightmattegray;
    }
  }

  .phone {
    margin-left: 2px;
  }

  .text-area {
    resize: none;
    font-family: 'Lato', sans-serif;
  }

  .copy-btn {
    position: absolute;
    right: 5px;
    top: 20px;

    button {
      border-radius: $border-radius-sm;
      height: 30px;
      width: 60px;
      border: none;
      background: $gray;

      &:focus {
        outline: unset;
      }

      &:hover {
        background: $mattegray;
      }
    }
  }

  .icon-right {
    position: absolute;
    right: 10px;
    top: 20px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
