.progress-bar-container {
  width: 80%;
  height: 5px;
  background: #999;
  border-radius: 2px;
  position: absolute;
  bottom: 20px;
  left: 5%;

  .progress-bar {
    width: 0%;
    height: 5px;
    background: #fff;
    border-radius: 2px;
  }

  .time-remaining {
    font-size: 14px;
    color: white;
    position: absolute;
    right: -40px;
    top: -5px;
  }
}
