.NotificationSettings {
  height: 100%;

  h1 {
    font-weight: bold;
    font-size: xx-large;
    text-align: center;
    padding-bottom: 30px;
  }

  p {
    text-align: center;
    font-size: x-large;
    padding-bottom: 30px;
  }

  .v-spacer {
    height: 10px;
  }

  .button-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .submit-button {
      min-width: 120px;
      margin: auto;
    }

    .btn {
      cursor: pointer;

      p {
        font-size: $text-sm;
        padding: initial;
      }
    }

    .cancel {
      color: $red;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-right: 5px;
      }
    }
  }

  .confirm-no-notifs {
    padding-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sub-title {
      font-size: $text-md;
    }
  }

  .confirm-change-contact {
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .sub-title {
      font-size: $text-sm;
      width: 80%;
      margin-left: 10%;
    }

    .InputField {
      margin-bottom: 30px;
    }
  }
}
