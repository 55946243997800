html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $white;
  font-family: 'Source Sans Pro', 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  object-fit: cover;
}

.route {
  width: 100%;
  padding-top: 50px;
  min-height: 100vh;
  position: relative;
}

.bold {
  font-weight: bold;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.fullscreen-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(65, 65, 65, 0.8);
  z-index: 9000;
  overflow: hidden;
}

.close-x {
  color: #fff;
  position: absolute;
  top: -25px;
  right: -15px;
  padding: 3px;
  cursor: pointer;
}

.modal-input {
  width: 220px;
  height: 22px;
  margin: 10px auto 10px auto;
  display: block;
  outline: none;
}

.modal-single-row {
  width: 220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.modal-button {
  padding: 5px 10px;
  background: $lightblue;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (min-width: 850px) {
  .route {
    padding-top: 100px;
  }
}
