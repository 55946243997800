.AddAdminModal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .sub-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .list {
    overflow: scroll;
    flex: 1;

    .user {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      img {
        height: 25px;
        margin-right: 10px;
        border-radius: $border-radius-sm;
      }

      .no-img {
        height: 25px;
        width: 25px;
        background: $green-gradient;
        margin-right: 10px;
        border-radius: $border-radius-sm;
      }

      p {
        flex: 1;
      }
    }
  }
}

.LeaveGroupModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sub-title {
    margin: 20px;
  }

  .title {
    font-size: $text-lg;
    font-weight: bold;
  }

  .button-container {
    max-width: 100%;
    display: flex;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;

    .Button {
      flex: 1;

      & + .Button {
        margin-left: 10px;
      }
    }
  }
}

.GroupSettings {
  height: 100%;
  display: flex;

  .loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 35px;
    }
  }

  .slack-btn-container {
    padding-top: 20px;
    align-self: center;
  }

  .container {
    padding: 30px;

    &.meta {
      overflow: scroll;
      flex: 2;
      max-width: 450px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .back-button {
        display: flex;
        align-items: center;

        svg,
        path,
        p {
          cursor: pointer;
          fill: $darkgray;
          margin-right: 10px;
        }
      }

      .title {
        h3 {
          margin: 20px 0px 10px;
          font-weight: bold;
          font-size: $text-lg;
          text-align: center;
        }

        .underline {
          height: 5px;
          width: 30px;
          background: $lightgreen;
        }
      }

      .upload-file {
        justify-content: flex-start;
        margin: 20px 0px;
      }

      .group-info {
        display: flex;
        flex-direction: column;
        .sub-title {
          margin-top: 60px;
        }
      }

      .actions {
        padding-top: 35px;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .leave-group {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: $border-radius-sm;
          position: relative;

          &:hover {
            background: rgba($red, 0.1);
          }

          svg,
          path {
            fill: $red;
            margin-right: 10px;
          }

          p {
            color: $red;
            user-select: none;
          }

          .hover-title {
            // hidden unless button is disabled and hovered on
            display: none;
            position: absolute;
            top: -15px;
            left: 0px;
            background: #ddd;
            color: black;
            font-size: 12px;
            padding: 2px 6px;
            white-space: nowrap;
            z-index: 3000;
          }

          // if the button is disabled, do different styling
          &.leave-group-disabled {
            &:hover {
              background: none;

              .hover-title {
                display: inline;
              }
            }
          }
        }

        .update-btn {
          width: 40%;
        }
      }
    }

    &.lists {
      background: $mediumgray;
      flex: 3;
      height: 100%;

      .tab-bar {
        display: flex;

        p {
          cursor: pointer;
          padding: 5px;
          border-radius: $border-radius-sm;

          &.current,
          &:hover {
            background: $gray;
          }
        }

        p + p {
          margin-left: 20px;
        }
      }

      .list-containers {
        height: 100%;
        padding-top: 30px;
        display: flex;

        .grouping {
          flex: 1;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 600;
          }

          .sub-title {
            margin-top: 20px;
          }

          .list {
            flex: 1;
            overflow: scroll;
            margin-bottom: 25px;

            ul {
              height: 100%;
              padding-top: 25px;

              .empty-list {
                margin-bottom: 10px;
              }

              li {
                display: flex;
                align-items: center;
                position: relative;

                &:hover {
                  .controls {
                    display: flex;
                  }
                }

                &.topic {
                  cursor: pointer;
                }

                .controls {
                  display: none;
                  position: absolute;
                  right: 0px;

                  .action {
                    background: $gray;
                    padding: 3px 4px;
                    border-radius: 50%;
                    color: $darkgray;
                    font-size: $text-xs;
                    cursor: pointer;

                    &.reject {
                      margin-right: 5px;
                    }
                  }
                }

                img,
                .no-img {
                  height: 25px;
                  width: 25px;
                  border-radius: $border-radius-sm;
                  border: none;
                  margin-right: 10px;
                }

                &:nth-of-type(5n + 1) {
                  img,
                  .no-img {
                    background: $green-gradient;
                  }
                }

                &:nth-of-type(5n + 2) {
                  img,
                  .no-img {
                    background: $blue-gradient;
                  }
                }

                &:nth-of-type(5n + 3) {
                  img,
                  .no-img {
                    background: $lime-gradient;
                  }
                }

                &:nth-of-type(5n + 4) {
                  img,
                  .no-img {
                    background: $orange-gradient;
                  }
                }

                &:nth-of-type(5n + 5) {
                  img,
                  .no-img {
                    background: $purple-gradient;
                  }
                }
              }

              li + li {
                margin-top: 10px;
              }
            }
          }

          &.members {
            margin-right: 15px;
          }
        }
      }

      .admin-settings {
        height: 100%;
        padding-top: 30px;
        display: flex;
        flex-direction: column;

        .list-containers {
          .grouping {
            &.admins {
              .list {
                ul {
                  .add-admin {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .loading {
    padding-top: 30%;
  }

  .GroupSettings {
    height: 100vh;
    flex-direction: column;
    overflow: scroll;

    .container {
      width: 100%;
      
      &.meta {
        max-width: unset;
        flex: unset;
        height: auto;
        overflow: unset;
      }

      &.lists {
        .admin-settings {
          height: auto;

          .list-containers {
            flex-direction: column;
            overflow: unset;

            .grouping {
              height: auto;
              flex: unset;
            }
          }
        }
      }
    }
  }
}