.Dropdown {
  position: relative;

  .dropdown-content {
    border-radius: $border-radius-sm;
    position: absolute;
    background: $black;
    padding: 10px;
    z-index: 100;
    bottom: 35px;

    &.active {
      display: block;
    }

    &.inactive {
      display: none;
    }

    ul {
      min-width: 220px;

      li {
        padding-right: 30px;
        color: darken($teal, 10%);
        padding: 10px 0px;
        cursor: pointer;
        position: relative;

        &:hover {
          color: $teal;
        }

        .icon {
          fill: darken($teal, 10%);
          height: 16px;
          width: 16px;
          position: absolute;
          right: 15px;
          top: calc(50% - 8px);
          display: none;

          &.selected {
            display: block;
          }
        }
      }

      li + li {
        border-top: 1px solid rgba($teal, 0.3);
      }
    }
  }
}
