.timeline-container {
    position: absolute;
    max-height: calc(92.5% - 80px);
    width: 80px;
    top: 2.5%;
    right: 2.5%;
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;

    .video-item {
        height: 80px;
        width: 80px;
        margin-bottom: 5px;
        box-sizing: border-box;
        border: 3px solid transparent;
        padding: 3px;
        border-radius: $border-radius-lg;
        position: relative;

        .most-recent-reaction {
            width: 20px;
            height: 20px;
            position: absolute;
            top: -3px;
            left: -3px;
        }

        &.blue-border {
            border: 3px solid $blueborder;
        }

        &.playing {
            border: 3px solid $white;

            img {
                border-radius: $border-radius-sm;
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: $border-radius-lg;
        }
    }


    .loading-thumbnail {
        width: 80px;
        height: 80px;
    }
}
