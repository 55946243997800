// === PRIMARIES === //
$lightgreen: #00ff94;
$green: #7ed221;

$darkblue: #003044;
$lightblue: #007caf;

$mattedarkblue: #005276;
$mattelightblue: #a8d5db;

$darkteal: #004460;
$matteteal: #25a4b4;
$teal: #03d6c6;

$red: #e84326;
$lightred: #ffe5e0;
$pink: #f60078;

// === GRAYSCALE === //
$white: #ffffff;
$lightgray: #eff4f7;
$mediumgray: #e5edf1;
$lightmattegray: #dceaf0;
$mattegray: #7e99a8;
$gray: #b0c4ce;
$darkgray: #3c3c3c;
$black: #000000;

// === TEXT COLORS === //
$input-placeholder: #3c5562;
$input-value: $black;
$input-disabled: #1c3542;

// === Video-Border === //
$blueborder: #007caf;

// === VIDEO TEXT COLORS === //
$text-white: $white;
$text-yellow: #eff003;
$text-red: #f60078;
$text-blue: #00b0e3;
$text-green: #7dd321;

$text-colors: (
  'white': $text-white,
  'yellow': $text-yellow,
  'red': $text-red,
  'blue': $text-blue,
  'green': $text-green,
);

// === GRADIENTS === //
$green-gradient: transparent linear-gradient(307deg, $lightgreen 0%, $lightblue 100%) 0% 0%
  no-repeat padding-box;
$green-gradient-radial: transparent radial-gradient(circle at 60% 35%, $lightblue, $lightgreen) 0% 0% no-repeat
  padding-box;
$black-gradient: transparent linear-gradient(180deg, rgba($black, 0), rgba($black, 1)) 0% 0%
  no-repeat padding-box;
$purple-gradient: transparent linear-gradient(315deg, #f23e9f 0%, #6c2196 100%) 0% 0% no-repeat
  padding-box;
$orange-gradient: transparent linear-gradient(315deg, #ffd803 0%, #fc4317 100%) 0% 0% no-repeat
  padding-box;
$blue-gradient: transparent linear-gradient(135deg, #003044 0%, #00c4ff 100%) 0% 0% no-repeat
  padding-box;
$lime-gradient: transparent linear-gradient(315deg, #d3ef00 0%, #00ac64 100%) 0% 0% no-repeat
  padding-box;

// ===== RADIUSES ===== //
$border-radius-xl: 12px;
$border-radius-lg: 8px;
$border-radius-md: 6px;
$border-radius-sm: 4px;

// ===== FONTS ===== //
$text-xs: 12px;
$text-sm: 16px;
$text-md: 20px;
$text-lg: 24px;
$text-xl: 28px;
$text-huge: 32px;

// ===== MAIN ===== //
$sidebar-width: 300px;
$header-height: 80px;
$chrome-flag-height: 50px;
$upload-flag-height: 25px;
