.Header {
  width: 100%;

  .metadata {
    height: $header-height;
    padding: 10px 20px;
    display: flex;

    .hamburger-container {
      display: none;
      margin-right: 10px;
      margin-top: 10px;
      min-width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;

      .hamburger,
      .hamburger:before,
      .hamburger:after {
        position: absolute;
        content: ' ';
        height: 3px;
        width: 100%;
        background: $darkteal;
        position: absolute;
      }

      .hamburger {
        top: calc(50% - 3px);

        &:before {
          top: calc(50% - 8px);
        }

        &:after {
          top: calc(50% + 4px);
        }
      }
    }

    .group-info {
      max-width: calc(100% - 20px);
      overflow: hidden;

      .group-name {
        font-size: $text-xl;
        color: $darkteal;
        margin: 0px;
        max-width: 100%;
        min-width: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 120%;
      }

      .group-members {
        color: $darkteal;
        display: flex;
        align-items: flex-end;
        line-height: 120%;
      }
    }

    .spacer {
      flex: 1;
    }

    .group-actions {
      display: flex;
      align-items: center;
    }
  }

  .button {
    width: auto;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    color: $matteteal;

    &.group-settings {
      .icon {
        width: 16px;
        height: 16px;
        fill: $matteteal;
        margin-right: 4px;
      }
    }

    &.group-invite {
      margin-right: 20px;

      .icon g use {
        fill: $matteteal;
      }
    }

    .icon {
      display: flex;
      height: 18px;
      width: 18px;
      margin-right: 4px;
    }

    .label {
      color: $lightgreen;
    }
  }

  .action-container {
    width: 100%;
    background: $white;
    padding: 0px 20px;
    border-bottom: $gray;
    border-top: $gray;
    border-bottom-width: thin;
    border-top-width: thin;
    border-style: solid;

    .actions {
      margin-top: 15px;
      display: flex;
      width: 100%;

      .spacer {
        flex: 1;
      }

      .action-label {
        display: flex;
        width: auto;
        padding-bottom: 10px;
      }

      .topics {
        padding-right: 20px;
        display: flex;
        flex-direction: row;
      }

      .direct-messages {
        display: flex;
        flex-direction: row;

        .unread {
          height: 8px;
          width: 8px;
          display: block;
          background: $red;
          border-radius: 100%;
          margin-left: 3px;
          margin-top: -3px;
        }
      }

      .selected {
        border-bottom-color: $lightgreen;
        border-bottom-style: solid;
        border-bottom-width: thick;
        color: $darkteal;
      }

      .not-selected {
        color: $mattegray;
      }

      .gray-svg {
        svg,
        path,
        g {
          fill: $mattegray;
        }
      }
    }
  }

  .filters {
    padding: 5px 20px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter-dropdown {
      cursor: pointer;
      padding: 5px 0px;

      .dropdown-content {
        background: $white;
        box-shadow: 2px 4px 10px 1px $mattegray;
        top: 30px;
        bottom: unset;

        ul {
          min-width: 130px;

          li {
            color: $darkgray;

            &:focus {
              background: $lightgray;
            }

            &:hover {
              background: $lightgray;
            }
          }

          li + li {
            border-top: 1px solid rgba($mattegray, 0.5);
          }
        }
      }

      .filter-type {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
      }

      .dropdown-arrow {
        margin-left: 10px;
        margin-top: 2px;
        height: 12px;
        width: 12px;
        fill: $darkgray;
      }
    }

    .view-type-container {
      cursor: pointer;
      padding: 5px 0px;
      min-width: 90px;

      .view {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-top: 2px;
          height: 12px;
          width: 12px;
        }

        .view-text {
          color: $matteteal;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: portrait),
  screen and (max-height: 768px) and (orientation: landscape) {
  .Header {
    .metadata {
      .hamburger-container {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait),
  screen and (max-height: 425px) and (orientation: landscape) {
  .Header {
    .metadata {
      height: 50px;

      .hamburger-container {
        margin-top: 7px;
      }

      .group-info {
        width: 100%;
        display: flex;
        align-items: center;

        .group-name {
          font-weight: 400;
          font-size: $text-sm;
        }

        .group-members {
          display: none;
        }
      }

      .spacer {
        min-width: 50px;
      }

      .group-actions {
        p {
          display: none;
        }
      }
    }
  }
}
