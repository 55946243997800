.conversation-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(50, 50, 50, 0.9);
  z-index: 8000;

  .conversation-modal-div {
    width: 680px;
    height: 920px;
    left: calc(50% - 340px);
    top: calc(50% - 460px);
    background: #fff;
    position: absolute;
    padding: 40px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .video-container {
      width: calc(100% - 100px);
      height: 100% !important;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: black;
      border-radius: 2px;

      video {
        width: 100% !important;
        height: auto !important;
        max-height: 100% !important;
      }

      .reply-button {
        width: 120px;
        color: white;
        position: absolute;
        bottom: 35px;
        left: calc(50% - 60px);
        padding: 8px 20px;
        background: #4100e4;
        border-radius: 5px;
        border: none;
        font-size: 15px;
        font-weight: 600;
      }

      .play-pause-button-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;

        .play-button {
          width: 40px;
          height: 40px;
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 20px);
        }
      }
    }

    .video-list {
      width: 70px;
      height: 100%;
      // smooth momentum scrolling on mobile
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      img {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
        cursor: pointer;
        border-radius: 3px;
        border-width: 4px;
        border-color: #4100e4;
      }
    }
  }
}

// screens too short for the full sized modal but not small enough to be a phone
@media only screen and (max-height: 1000px) and (min-width: 851px) {
  .conversation-modal {
    .conversation-modal-div {
      width: 440px;
      height: 560px;
      left: calc(50% - 220px);
      top: calc(50% - 280px);
      padding: 20px 20px;
    }
  }
}

// Full screen for phones
@media only screen and (max-width: 850px) {
  .conversation-modal {
    .conversation-modal-div {
      width: 100vw !important;
      height: 100vh !important;
      padding: 0px 0px !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;

      .close-x {
        width: fit-content;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 30px;
        left: 5px !important;
        top: 5px !important;
        font-size: 24px;
        text-shadow: 2px 2px black;
        color: white;
        z-index: 800;
      }

      .video-container {
        width: 100% !important;
      }

      .video-list {
        position: absolute;
        height: 70%;
        top: 15%;
        right: 5px;

        img {
          border-color: #fff !important;
        }
      }
    }
  }
}

// Phone flipped sideways
@media only screen and (max-height: 415px) {
  .video-list {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 3% 0px !important;
  }
}
