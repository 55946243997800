.eliipses-button {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  left: 5%;
  cursor: pointer;
}

.elipses-menu-container {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1200;

  .elipses-menu {
    width: 99%;
    height: fit-content;
    position: absolute;
    bottom: 0px;
    left: 0.5%;
    background: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .close-elipses-menu-button {
      color: #fff;
      position: absolute;
      font-size: 20px;
      top: -25px;
      right: 10px;
      padding: 3px;
      cursor: pointer;
    }

    .elipses-menu-row {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid gray;
      padding: 20px 10px;
      position: relative;
      display: flex;
      align-content: center;
      cursor: pointer;

      img {
        margin-right: 10px;
        cursor: pointer;
      }

      p {
        text-align: center;
        padding-top: 10px;
        cursor: pointer;
      }

      .flag-video-menu {
        width: auto;
        height: 0%;
        overflow: hidden;
        position: absolute;
        right: 10px;
        bottom: 0px;
        padding: 5px 10px;
        background: white;
        border-top: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-radius: 4px;
        transition: height 0.3s linear;

        .flag-video-menu-buttons-container {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;

          .flag-video-menu-button {
            width: 80px;
            height: 30px;
            background: #009dca;
            color: white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
