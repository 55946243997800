.AddContent {
  position: relative;
  height: 100%;
  overflow: hidden;

  .header {
    padding-top: 5px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .back {
      position: absolute;
      left: 0px;
      background: unset;
      border: unset;
      color: $matteteal;
    }

    .title {
      text-align: center;
    }
  }

  .view {
    position: absolute;
    height: 90%;
    width: 100%;
    overflow: scroll;
    transition: left 0.3s ease-in-out;

    &.hide {
      left: 100%;
    }

    &.show {
      left: 0%;
    }

    .InputField {
      margin: 0px;
    }

    .list-label {
      margin: 20px 0px;
    }

    .options {
      position: relative;
      height: unset;

      .option {
        &.wide {
          padding: 25px 0px;
        }

        .invite-url {
          .copy-btn {
            top: 5px;
          }
        }
      }
    }
  }

  .options {
    position: absolute;
    width: 100%;
    height: 90%;
    overflow: scroll;
    transition: left 0.3s ease-in-out;

    &.hide {
      left: -100%;

      &.right {
        left: 100%;
      }
    }

    &.show {
      left: 0%;
    }

    .add-folder-btn {
      width: 100%;
    }

    .option {
      padding: 25px 10px;
      border-top: 1px solid $gray;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &.noclick {
        cursor: unset;
      }

      &.norow {
        border-top: unset;
      }

      &:not(.noclick):hover {
        .label,
        .count {
          color: $matteteal;
        }
      }

      .meta {
        display: flex;
        justify-content: center;
        align-items: center;

        &.column {
          flex-direction: column;
        }

        &.wide {
          width: 100%;
        }

        .icon {
          max-height: 30px;
          max-width: 30px;
        }

        .label {
          margin-left: 10px;

          &.center {
            margin: auto;
            width: 100%;
            text-align: center;
          }
        }

        .description {
          margin-bottom: 20px;
          text-align: center;
        }
      }

      .count {
        margin-right: 25px;
      }

      .confirm-btn {
        width: 45%;

        &.delete {
          background: $red;

          &:hover {
            background: darken($red, 20);
          }
        }

        & + .confirm-btn {
          margin-left: 10%;
        }
      }
    }
  }
}
