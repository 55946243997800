.Breadcrumb {
  padding: 10px 20px 0px;

  ul {
    display: flex;

    li {
      & + li {
        margin-left: 5px;
      }

      a,
      a:visited {
        color: $teal;
      }
    }
  }
}
