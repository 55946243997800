.loading-gif-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(200, 200, 200, 0.1);
  z-index: 9999;
}

.loading-gif {
  width: 60px;
  height: 60px;
  position: fixed;
  left: calc(50% - 30px);
  top: calc(50% - 30px);

  // a local loading gif should be centered in its parent rather than the whole screen
  &.local {
    position: absolute;
  }
}

// smaller on phones
@media only screen and (max-width: 850px) {
  .loading-gif {
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
  }
}
