.conversation-preview {
  width: 100%;
  margin: 20px 0px;
  overflow: hidden;

  .conversation-icon {
    width: 45px;
    height: 45px;
    border-radius: 3px;
  }

  .conversation-fallback-image {
    width: 45px;
    height: 45px;
    background: linear-gradient(130deg, yellow, green);
    position: relative;
    border-radius: 3px;

    p {
      font-size: 20px;
      font-weight: bold;
      color: white;
      position: absolute;
      top: 12px;
      left: 15px;
    }
  }

  .conversation-name-date-wrapper {
    padding: 6px 4px;

    .conversation-name {
      font-size: 18px;
    }

    .conversation-updatedDate {
      font-size: 12px;
    }
  }

  .conversation-gifs-wrapper {
    width: 100%;
    max-width: 100%;
    height: 135px;
    margin: 5px 0px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    // all children should have same physical dimensions
    * {
      flex: 0 0 auto;
      position: relative;
      width: 100px;
      height: 135px;
      border-radius: 4px;
      margin: 0px 2px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }

    .no-videos-div {
      background: black;
      color: white;
      text-align: center;
      padding: 55px 0px;
    }

    .gray-background-preview {
      background: #d8d8d8;
    }

    .view-all-modal {
      width: 100%;
      height: 100%;
      padding: 55px 0px;
      background: rgba(50, 50, 50, 0.5);
      color: white;
      text-align: center;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1000;
    }
  }
}
