.ConfirmDeleteDMModal {
  height: 100%;
  padding-top: 40%;

  .title {
    font-size: $text-md;
    font-weight: 700;
    text-align: center;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    .Button {
      flex: 1;
      margin: 10px;
    }
  }
}

.GroupMessagePreview {
  height: 100%;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .new-conversation-container {
    margin-bottom: 10px;

    .add-content-btn {
      padding: 0px 10px;

      .plus-icon {
        margin-right: 10px;
        height: 20px;
        width: 20px;
        position: relative;

        &::before {
          content: ' ';
          height: 18px;
          width: 2px;
          background: $white;
          display: block;
          position: absolute;
          top: calc(50% - 9px);
          left: calc(50% - 1px);
        }

        &::after {
          content: ' ';
          height: 2px;
          width: 18px;
          background: $white;
          display: block;
          position: absolute;
          top: calc(50% - 1px);
          left: calc(50% - 9px);
        }
      }
    }
  }

  .conversation-container {
    height: 100%;

    .conversation {
      display: flex;
      flex-direction: column;

      .meta-container {
        height: 40px;
        display: flex;
        position: relative;

        .conversation-image {
          height: 40px;
          width: 40px;
          position: relative;
          cursor: pointer;

          svg {
            height: 100%;
            width: 100%;
          }

          .profile-picture {
            position: absolute;
            left: 10px;
            top: 12px;
            height: 20px;
            width: 20px;
            border-radius: $border-radius-sm;
          }
        }

        .meta {
          padding: 0px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          cursor: pointer;

          .title {
            font-size: $text-sm;
          }

          .timestamp {
            font-size: $text-xs;
          }
        }

        .options {
          position: absolute;
          right: 0px;
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            height: 80%;
            width: 80%;
          }
        }
      }

      .cards {
        margin-top: 10px;
        flex: 1;
        overflow-x: scroll;
        display: flex;
        transition: height 0.5s ease-in-out, min-height 0.5s ease-in-out;

        &.expanded {
          height: 135px;
          min-height: 135px;
        }

        &.collapsed {
          min-height: 0px;
          height: 0px;
          overflow: hidden;

          .card {
            transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
            opacity: 0;
            height: 0px;

            &.unread {
              border: 3px solid transparent;
            }
          }
        }

        .card {
          transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
          height: 135px;
          width: 90px;
          min-width: 90px;
          background: $mattegray;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          border-radius: $border-radius-sm;

          &.unread {
            border: 3px solid $blueborder;
          }

          .preview-giphy {
            height: 100%;
            width: 100%;
          }

          .overlay {
            position: absolute;
            bottom: 0px;
            height: 20px;
            width: 100%;
            background: $black-gradient;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $text-xs;
            text-align: center;

            &.full {
              height: 100%;
              background: rgba($black, 0.8);
              font-size: $text-sm;
              padding: 10px;
            }

            .timestamp,
            .label {
              color: $white;
            }
          }
        }

        .card + .card {
          margin-left: 20px;
        }
      }
    }

    .conversation + .conversation {
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .GroupMessagePreview {
    .new-conversation-container {
      position: absolute;
      bottom: 10px;
      right: 20px;
      z-index: 100000;

      .add-content-btn {
        padding: 0px 10px;
        border-radius: 100%;
        height: 50px;
        box-shadow: 3px 3px 6px 1px rgba($darkgray, 0.7);

        .plus-icon {
          margin-right: 0px;
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
