@keyframes bounce {
  0% {
    height: 25px;
  }
  50% {
    height: 30px;
  }
  100% {
    height: 25px;
  }
}

.App {
  font-family: 'Source Sans Pro', 'Lato', sans-serif !important;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto minmax(0, 1fr);

  .flags {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    transition: height 0.2 ease-in-out;

    .chrome-flag {
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: $chrome-flag-height;
      background: $lightblue;
      color: $white;

      p {
        margin-left: 5px;
      }
    }

    .top-bar {
      height: $upload-flag-height;
      background: $lightblue;
      width: 100vw;
      z-index: 100;
      transition: height 0.2s ease-in-out, background 0.2s linear;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 0px 20px;
      display: flex;

      p {
        display: block;
        color: $white;
        font-weight: 600;

        &.count {
          position: absolute;
          left: 5px;
        }
      }
    }
  }

  .Main {
    overflow: scroll;
  }
}
