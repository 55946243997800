.reaction-animation-modal {
    width: 0;
    height: 0;
    background: rgba(255,255,255,0);
    z-index: -5000;

    img {
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 9000;
    }
}