.admin-settings-container {
  h1 {
    margin-bottom: 10px;
  }
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border: black solid 1px;
  border-radius: 4px;
}

.admin-controls {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.admin-add {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.admin-delete {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.admin-deny {
  padding: 2px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.admin-approve {
  padding: 2px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.admin-settings-button {
  margin-bottom: 4px;
}
