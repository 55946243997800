.VideoOptionsModal {
  position: relative;
  height: 100%;
  overflow: hidden;

  .header {
    padding-top: 5px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .back {
      position: absolute;
      left: 0px;
      background: unset;
      border: unset;
      color: $matteteal;
    }

    .title {
      text-align: center;
    }
  }

  .view {
    position: absolute;
    height: 90%;
    width: 100%;
    overflow: scroll;
    transition: left 0.3s ease-in-out;

    &.hide {
      left: 100%;
    }

    &.show {
      left: 0%;
    }

    .InputField {
      margin: 0px;
    }

    .list-label {
      margin: 20px 0px;
    }

    .options {
      position: relative;
      height: unset;

      .option {
        &.wide {
          padding: 25px 0px;
        }

        .tag-btn {
          min-height: unset;
          height: 30px;
          width: 60px;

          &.tag {
            border: 1px solid $matteteal;
            background: $white;
            color: $matteteal;

            &:hover {
              background: darken($white, 5);
            }
          }
        }
      }
    }
  }

  .options {
    position: absolute;
    width: 100%;
    height: 90%;
    overflow: scroll;
    transition: left 0.3s ease-in-out;

    &.hide {
      left: -100%;

      &.right {
        left: 100%;
      }
    }

    &.show {
      left: 0%;
    }

    .option {
      padding: 25px 10px;
      border-top: 1px solid $gray;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &.noclick {
        cursor: unset;
      }

      &.norow {
        border-top: unset;
      }

      &:not(.noclick):hover {
        .label,
        .count {
          color: $matteteal;
        }
      }

      &.delete {
        color: $red;

        &:not(.noclick):hover {
          .label,
          .count {
            color: darken($red, 20);
          }
        }

        .meta {
          .icon {
            fill: $red;

            line {
              stroke: $red;
            }
          }
        }
      }

      .meta {
        display: flex;
        justify-content: center;
        align-items: center;

        &.wide {
          width: 100%;
        }

        .icon {
          max-height: 20px;
          max-width: 20px;

          &.small {
            max-width: 15px;
            margin-left: 1px;
          }

          &.large {
            max-height: 25px;
            max-width: 25px;
          }
        }

        .label {
          margin-left: 10px;

          &.center {
            margin: auto;
            width: 100%;
            text-align: center;
          }
        }
      }

      .count {
        margin-right: 25px;
      }

      .confirm-btn {
        width: 45%;

        &.delete {
          background: $red;

          &:hover {
            background: darken($red, 20);
          }
        }

        & + .confirm-btn {
          margin-left: 10%;
        }
      }
    }
  }
}

.Playback,
.Record {
  height: 100%;
  overflow: hidden;
  position: relative;

  .video-player {
    background: $black;
    height: 100%;

    .video {
      height: 100%;
      width: 100%;
    }
  }

  .video-controls-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    top: 0px;

    .navigation-container {
      position: absolute;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      top: 2.5%;
      left: 2.5%;
      display: flex;
      flex-direction: column;

      .back-button {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          cursor: pointer;
        }

        p {
          color: $lightgreen;
          cursor: pointer;
        }
      }

      .topic-info {
        display: flex;
        align-items: center;
        margin-top: 25px;

        img {
          background: $green-gradient;
          height: 25px;
          width: 25px;
          margin-right: 10px;
          border-radius: $border-radius-sm;
        }

        p {
          color: $white;
        }
      }
    }

    .text-on-video-overlay {
      position: absolute;
      min-height: 50px;
      width: 300px;
      left: calc(50% - 150px);
      top: 200px;

      .overlay-text {
        color: $white; // default if for some reason the color in the video.textOnVideo.color is not working
        width: 100%;
        text-align: center;
        cursor: default;
        font-size: $text-md;
      }
    }

    .center-play-icon {
      position: absolute;
      height: 100px;
      width: 200px;
      top: calc(50% - 50px);
      left: calc(50% - 100px);
      display: none;
      align-items: center;

      &.show {
        display: flex;
      }

      svg {
        height: 50px;
        width: 50px;
        cursor: pointer;

        &.play {
          height: 100px;
          width: 100px;
          margin: 0px 20px;
        }
      }
    }

    .controls {
      position: absolute;
      height: 90px;
      width: 95%;
      left: 2.5%;
      bottom: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 0px 5px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .meta {
        min-height: 35px;
        display: flex;
        justify-content: space-between;

        .publisher {
          display: flex;
          align-items: flex-end;
          color: $white;

          .author {
            margin-right: 10px;
          }

          .timestamp {
            color: rgba($white, 0.5);
          }
        }

        .attachment {
          .attach-url-btn {
            color: $lightgreen;
            background: transparent;
            border: 2px solid $lightgreen;

            &:hover {
              background: $lightgreen;
              color: $black;
            }

            .attach-url-btn-icon {
              margin-right: 10px;
              border-radius: $border-radius-sm;
            }
          }
        }
      }

      .controls-action-container {
        padding: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: transparent;

        .actions {
          min-width: 150px;
          display: flex;

          .action-item {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .action-list-dropdown {
              .dropdown-content {
                padding: 5px;
                bottom: 35px;

                .action-list {
                  display: flex;
                  min-width: 80px;

                  li {
                    padding: 0px 5px;
                    border-top: unset;

                    svg {
                      height: 20px;
                      width: 20px;
                      fill: $white;
                    }
                  }
                }
              }
            }

            .action-item-logo {
              height: 28px;
              width: 28px;

              svg,
              path,
              g,
              circle {
                fill: $white !important;
              }

              &:hover {
                .action-item-logo-img {
                  background: $white;
                  fill: $black;

                  circle,
                  .eye,
                  .smile {
                    fill: $black !important;
                  }
                }

                .action-item-logo-text {
                  color: $black;
                  background: $white;
                }
              }

              .action-item-logo-img,
              .action-item-logo-text {
                height: 28px;
                width: 28px;
                border-radius: 100%;
                cursor: pointer;
              }

              .action-item-logo-text {
                border: 2px solid $white;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $text-xs;
              }
            }
          }
        }

        .reaction-options {
          width: 240px;
          padding: 8px;
          background: rgba(75, 75, 75, 0.8);
          border-radius: 20px;
          position: absolute;
          top: -45px;
          left: 0px;
          display: flex;
          justify-content: space-around;

          .reaction-option {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }

        .progress-bar {
          flex: 1;
          display: flex;
          padding: 0px 20px;

          .video-control-time {
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $text-md;
          }

          .video-control-slider {
            flex: 1;
            padding: 0px 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .Slider {
              flex: 1;
              background: rgba($white, 0.3);
              height: 5px;
              cursor: pointer;

              .slider-bar {
                background: $white;
                border-radius: $border-radius-sm;
                height: 100%;
              }

              .slider-handle {
                background: $white;
                height: 18px;
                width: 18px;
                border-radius: 100%;
                margin-left: 8px;
                position: absolute;
                top: -6px;
              }
            }
          }
        }

        .record {
          min-width: 150px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .record-button {
            background: $matteteal;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            height: 50px;
            width: 50px;

            &:hover {
              background: darken($matteteal, 15);

              .reply-icon {
                svg,
                path,
                rect {
                  fill: darken($white, 15);
                }
              }
            }

            .reply-icon {
              max-height: 80%;
              max-width: 80%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Playback {
    .video-controls-overlay {
      .center-play-icon {
        svg {
          height: 35px;
          width: 35px;
        }

        svg.play {
          height: 50px;
          width: 50px;
        }
      }

      .timeline-container {
        width: 65px;

        .video-item {
          width: 65px;
          height: 65px;
        }
      }

      .controls {
        .controls-action-container {
          .actions {
            min-width: unset;

            .action-item {
              margin-right: 10px;
            }
          }

          .progress-bar {
            width: 100%;
            padding: 0px 10px;

            .video-control-slider {
              padding: 0px 10px 0px 0px;

              .Slider {
                height: 10px;
                border-radius: 5px;
              }
            }
          }

          .record {
            min-width: unset;
          }
        }
      }
    }
  }
}
