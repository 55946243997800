.TagUsersModal {
  .modal-content {
    position: relative;

    h3 {
      font-size: $text-lg;
      margin-bottom: 20px;
    }

    .tag-users-search {
      width: 50%;
      margin: auto;
      margin-bottom: 10px;
    }

    .table-container {
      margin: auto;
      width: 50%;
      max-height: 60vh;
      overflow: scroll;
      border: 1px solid $lightgray;

      .tag-users-table {
        width: 100%;

        thead {
          tr {
            td {
              padding: 5px;
              font-weight: bold;
              border-bottom: 1px solid $lightgray;
            }
          }
        }

        tbody {
          overflow: scroll;

          tr {
            td {
              position: relative;
              padding: 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .user-profile-picture {
                border-radius: 50%;
                background: lighten($lightblue, 30);
                height: 25px;
                width: 25px;
              }

              .selected-icon {
                color: green;
                visibility: hidden;

                &.selected {
                  visibility: visible;
                }
              }

              &:hover {
                background: rgba(lightgreen, 0.2);
                cursor: pointer;

                &.selected {
                  background: rgba(red, 0.1);
                }
              }
            }

            & + tr {
              border-top: 1px solid $lightgray;
            }
          }
        }
      }
    }

    .Button {
      width: 50%;
      margin-top: 40px;
    }
  }
}
