.OnboardingModal {
  height: 100%;
  width: 500px;

  .step {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    align-items: center;

    .title {
      font-size: $text-xl;
      text-align: center;
      font-weight: 700;
    }

    svg {
      height: 150px;
      margin-top: 30px;
    }

    p {
      font-size: $text-md;
      text-align: center;
      max-width: 90%;
      margin-bottom: 20px;
      line-height: 1.3em;
      min-height: 100px;
    }

    .Button {
      min-width: 150px;
    }
  }
}

@media (max-width: 768px) {
  .OnboardingModal {
    width: unset;
  }
}

.LandingPage {
  height: 100%;
  display: flex;

  .container {
    flex: 1;
    position: relative;

    &.branding {
      flex: 1.2;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .background-tilted {
        position: absolute;
        background: $green-gradient-radial;
        height: 150%;
        width: 150%;
        transform: rotate(5deg);
        right: 70px;
        top: -70px;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
      }

      .content {
        position: absolute;
        height: 40%;
        width: 60%;
        margin-left: -5%;

        .logo {
          width: 100%;
          max-height: 80px;
          object-fit: contain;
          object-position: left;
        }

        .separator {
          height: 5px;
          width: 50px;
          background: $white;
          margin: 30px 0px;
        }

        .copy {
          color: $white;
          font-size: $text-md;
        }
      }
    }

    &.login {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .form {
        width: 60%;
        max-width: 400px;
        min-height: 50%;

        .login-code {
          label {
            font-size: $text-xs;
          }
        }

        .sign-in {
          font-size: $text-lg;
          font-weight: 700;
          margin-bottom: 30px;
        }

        .authentication-dropdown {
          .dropdown-content {
            background: $white;
            box-shadow: 2px 4px 10px 1px $mattegray;
            width: 100%;
            top: 55px;
            bottom: unset;

            ul {
              li {
                color: $darkgray;

                &:focus {
                  background: $lightgray;
                }
              }

              li + li {
                border-top: 1px solid rgba($mattegray, 0.5);
              }
            }
          }
        }

        .input-dropdown {
          .input-field {
            cursor: pointer;
          }

          .dropdown-arrow {
            height: 12px;
            width: 12px;
            fill: $darkgray;
          }
        }

        .auth-btn {
          margin: 10px 0px;
          min-width: 100px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .LandingPage {
    flex-direction: column;

    .container {
      &.branding {
        flex: 2;

        .background-tilted {
          width: 130%;
          transform: rotate(-5deg);
          right: unset;
          left: -20px;
          bottom: 50px;
          top: unset;
        }

        .content {
          margin-left: unset;
          display: flex;
          justify-content: center;

          .logo {
            max-height: 40px;
            width: auto;
          }

          .separator,
          .copy {
            display: none;
          }
        }
      }

      &.login {
        flex: 8;
        justify-content: flex-start;
        padding-top: 50px;

        .form {
          // width: 90%;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .LandingPage {
    flex-direction: column;

    .container {
      &.branding {
        flex: 1.3;

        .background-tilted {
          width: 110%;
          transform: rotate(-5deg);
          background: $green-gradient;
          right: 0px;
          bottom: 20px;
          top: unset;
        }

        .content {
          .separator,
          .copy {
            display: none;
          }
        }
      }

      &.login {
        flex: 8;
        justify-content: flex-start;
        padding-top: 50px;

        .form {
          width: 90%;
        }
      }
    }
  }
}
