.ToggleSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  .switch {
    position: relative;
    min-width: 50px;
    width: 50px;
    height: 26px;
    margin-right: 15px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  input {
    &:checked {
      & + .slider {
        background-color: $matteteal;

        &:before {
          -webkit-transform: translateX(23px);
          -ms-transform: translateX(23px);
          transform: translateX(23px);
        }
      }
    }

    &:focus {
      & + .slider {
        box-shadow: 0 0 1px $matteteal;
      }
    }
  }
}
